<template>
  <FocusTrap>
  <div class="card">

    <div class="page-header page-header-light" >
      <div class="page-header-content header-elements-lg-inline" >
        <div class="page-title d-flex" style="padding: 9px;">
          <h6>
            <i class="icon-list mr-2"></i> <span class="font-weight-semibold">Purchase Payment </span> - List
          </h6>
          <a href="#" class="header-elements-toggle text-body d-lg-none"><i class="icon-more"></i></a>
        </div>

        <div class="header-elements d-none">
          <form action="#">
            <div class="form-group">

              <div class="input-group input-group-feedback input-group-feedback-right wmin-lg-200">
                <input id="txtsearch" type="text" class="form-control wmin-lg-200" placeholder="Search" autocomplete="off" style="padding-left: 20px;">
                <div class="form-control-feedback">
                  <i class="icon-search4"></i>
                </div>

                <div class="input-group-append position-static">
                  <button type="button" class="btn btn-outline-secondary btn-icon" @click="showFilterWindow">
                    <i class="icon-gear"></i>
                  </button>
                </div>

              </div>
            </div>
          </form>
        </div>
      </div>
    </div>


    <div class="card-body">
      <div class="table-responsive">
      <table id="payment_list_table"
             class="table table-columned table-hover"
             data-search="false"
             data-pagination="false"
             data-show-refresh="false"
             data-show-columns="false"
             data-page-list="[10, 25, 50, 100, ALL]"
             data-show-footer="false"
             data-toggle="context"
             data-row-style="rowStyle"
             data-row-attributes="rowAttributes"
             data-target=".context-table">
        <thead>
        <tr>
          <th data-field="id" data-class="d-none">id</th>
          <th data-field="status" data-class="d-none">Status</th>
          <th data-formatter="runningFormatter" data-width="75" >S.No</th>
          <th data-field="pay_date" data-width="110" data-formatter="dateFormatter" data-sortable="true">Date</th>
          <th data-field="purch_no" data-width="100" data-sortable="true">Purch No</th>
          <th data-field="inv_no" data-width="100" data-sortable="true">Inv No</th>
          <th data-field="ledger.name" data-sortable="true">Paid By</th>
          <th data-field="amount" data-width="100" data-align="right" data-formatter="indianFormat" data-sortable="true">Amount</th>
          <!--<th data-field="tds_amt" data-width="70" data-align="right" data-formatter="indianFormat" data-sortable="true">TDS</th>-->
          <th data-field="acc_cd" data-width="70" data-align="right" data-sortable="true">Acc Code</th>
          <th data-field="remarks" data-sortable="true">Remarks</th>
        </tr>
        </thead>
        <tbody>

        </tbody>
        <tfoot>
        </tfoot>
      </table>
    </div>


      <!-- Context Menu -->
      <div id="context-menu" class="context-table">
        <div class="dropdown-menu">
          <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: darkgreen"></i>Refresh</a>
          <a href="#" class="dropdown-item" ><i class="icon-file-eye2" ></i>Invoice Preview</a>

          <div class="dropdown-divider"></div>
          <!--<a href="#" class="dropdown-item" ><i class="icon-cash4" style="color: darkgreen"></i>Payment</a>-->
          <!--<a href="#" class="dropdown-item" style="color: blue;"> <i class="icon-pencil3" ></i>Modify Purchase</a>-->
          <a href="#" class="dropdown-item" > <i class="icon-pencil5" ></i>Modify Payment</a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item" style="color: red"> <i class="icon-blocked" ></i>Cancel</a>
        </div>
      </div>
      <!-- End of Context Menu -->

      <div class="modal" id="payment_modal"  >
        <div class="modal-dialog  modal-full modal-dialog-centered modal-dialog-scrollable" >
          <div class="modal-content" >

            <!-- Modal body -->
            <div class="modal-body" >
              <PaymentForm v-if="invoice.code > 1" v-bind:invoice="invoice" v-on:payment_updated="onPaymentUpdate" > </PaymentForm>
            </div>


          </div>
        </div>
      </div>

      <div class="modal" id="previewmodal"  >
        <div class="modal-dialog  modal-full modal-dialog-centered modal-dialog-scrollable" >
          <div class="modal-content" >

            <!-- Modal body -->
            <div class="modal-body" >
              <PurchaseInvoicePreview v-if="invoice.code > 1" v-bind:invoice="invoice" > </PurchaseInvoicePreview>
            </div>


          </div>
        </div>
      </div>
    </div>

  </div>
  </FocusTrap>
</template>

<script>
  import InvoiceForm from '@/views/tms/vouchers/invoice/InvoiceForm.vue'
  import PaymentForm from '@/components/v1/purchasepayment/PurchasePayment.vue'
  import PurchaseInvoicePreview from '@/views/tms/vouchers/invoice/PurchaseInvoicePreview.vue'

  import FabButton from '@/components/core/FabButton.vue'
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  import { store } from '@/store/store.js'
  export default {
    name: 'PurchasePaymentView',
    components: {
      InvoiceForm,
      PaymentForm,
      PurchaseInvoicePreview,
      FabButton
    },
    store,
    data () {
      return {
        mytable: {},
        invoice: JSON.parse('{"id":"","code":0,"ver":0,"status":0,"is_cr":"Y","type":0,"finyear":0,"series":302,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","ledger":{"id":"","code":0,"type":0,"group_code":0,"name":"","print_name":"","street":"","city":"","pin":"","state_id":0,"gstin":"","contact_person":"","telephone":"","email":""},"eway_no":"","eway_date":"0001-01-01","vehicle_no":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"remarks":"","gross_amount":0,"tax_amount":0,"round_off":0,"net_amount":0,"list":[]}'),
        finyear:  2018,
        series: 302,
      }
    },
    created () {

    },
    mounted () {
      let self = this;

      this.$data.mytable = $('#payment_list_table');
      this.$data.mytable.bootstrapTable();
      $('#payment_list_table').on('post-body.bs.table', function (e) {
        $('[data-toggle="popover"]').popover();
      });

      this.$data.mytable.contextmenu({
        target: '#context-menu',
        scopes: 'tbody > tr',
        onItem: function (row, e) {

          var id = $(row.children('*')[0]).text();
          var status =  parseInt($(row.children('*')[1]).text());


          if ($(e.target).text() === 'Modify Purchase') {
            if (status === 0) {
              self.modifyDocument(id);
            }
          }else if ($(e.target).text() === 'Modify Payment') {
            if(status >= 0) {
              self.modifyPayment(id, status);
            }
          }else if ($(e.target).text() === 'Cancel') {

              self.removeDocument(id, status);

          }else if ($(e.target).text() === 'Payment') {
            self.invoicePayment(id, status);
          }else if ($(e.target).text() == 'Invoice Preview'){
            self.invoicePreview(id);
          }
        }
      });

      $('#txtsearch').keyup(function () {
        let val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();
        $('#payment_list_table>tbody>tr').show().filter(function () {
          var text = $(this).text().replace(/\s+/g, ' ').toLowerCase();
          return !~text.indexOf(val);
        }).hide();
      });

      self.loadData();

      // $(window).resized(function(){
      //   $("#payment_list_table").attr("data-height",$('#appfooter').position().top - $('.content-wrapper').position().top - 100 );
      //   $("#payment_list_table").bootstrapTable('resetView');
      // },300);

    },
    methods: {
      closeModal() {

      },
      beforeOpen(){

      },
      beforeClose(){
        // this.$data.invoice = JSON.parse('{"id":"","code":0,"ver":0,"status":0,"is_cr":"Y","type":0,"finyear":2018,"series":302,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","ledger":{"id":"","code":0,"type":0,"group_code":0,"name":"","print_name":"","street":"","city":"","pin":"","state_id":0,"gstin":"","contact_person":"","telephone":"","email":""},"eway_no":"","eway_date":"0001-01-01","vehicle_no":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"remarks":"","gross_amount":0,"tax_amount":0,"round_off":0,"net_amount":0,"list":[]}');
      },
      showModal () {

      },
      onPaymentUpdate(){
        this.loadData();
        $('#payment_modal').modal('hide');
      },
      invoicePreview(id){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode: "cors",
          headers: userService.authHeader()
        };

        self.$data.invoice = {};
        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/transaction/${id}/`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.accepted) {

            self.$data.invoice = resp.data;
            self.$data.invoice.doc_date = moment(resp.data.doc_date).format('YYYY-MM-DD');
            self.$data.invoice.ref_date = moment(resp.data.ref_date).format('YYYY-MM-DD');
            $('#previewmodal').modal('show');

          } else {
            swal({ title: "Oh noes!", text: resp.message, type: "error" });
          }
        }).catch(function (err) {
          swal({ title: "Oh noes!", text: err.toString(), type: "error" });
        });

      },
      invoicePayment (id, status) {
        let self = this;

        if(status == 0 ) {
          const requestOptions = {
            method: 'GET',
            mode: "cors",
            headers: userService.authHeader()
          };

          self.$data.invoice = {};
          fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/transaction/${id}/`, requestOptions).then(userService.handleResponse).then(function (resp) {
            if (resp.accepted) {

              self.$data.invoice = resp.data;
              self.$data.invoice.doc_date = moment(resp.data.doc_date).format('YYYY-MM-DD');
              self.$data.invoice.ref_date = moment(resp.data.ref_date).format('YYYY-MM-DD');
              // self.$modal.show('invoice-window');
              $('#payment_modal').modal('show');

            } else {
              swal({ title: "Oh noes!", text: resp.message, type: "error" });
            }
          }).catch(function (err) {
            swal({ title: "Oh noes!", text: err.toString(), type: "error" });
          });
        }
      },
      modifyPayment (id) {
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode: "cors",
          headers: userService.authHeader()
        };

        self.$data.invoice = {};
        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/transaction/${id}/`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.accepted) {

            self.$data.invoice = resp.data;
            self.$data.invoice.doc_date = moment(resp.data.doc_date).format('YYYY-MM-DD');
            self.$data.invoice.ref_date = moment(resp.data.ref_date).format('YYYY-MM-DD');
            self.$data.invoice.status = 1;

            $('#payment_modal').modal('show');

          } else {
            swal({ title: "Oh noes!", text: resp.message, type: "error" });
          }
        }).catch(function (err) {
          swal({ title: "Oh noes!", text: err.toString(), type: "error" });
        });


      },
      removeDocument (id) {
        let self = this;

        self.$data.invoice.id = id;

        const requestOptions = {
          method: 'DELETE',
          mode:'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.invoice)
        };

        swal({
          title: 'What is the reason for cancel this transaction?',
          input: 'text',
          inputPlaceholder: 'Enter Remarks here',
          showCancelButton: true,
          inputClass: 'form-control',
          inputValidator: function(value) {
            return !value && 'You need to write something!'
          }
        }).then(function(result) {
          if(result.value) {
            fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/purchasepayment/${id}/?remarks=${result.value}`,requestOptions).then(userService.handleResponse).then(function (resp) {
              // self.$modal.hide('receipt-window');
              if(resp.accepted){
                self.loadData();
                swal({
                  title: 'Canceled!',
                  text: 'Your request has been processed',
                  type: 'success'
                });
              }else {
                swal({ title: "Oops", text: resp.message, type: "info" });
              }

            }).catch(function (err) {
              swal({ title: "Oops", text: err.toString(), type: "error" });
            });
          }
        });


      },
      loadData () {
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        $('#txtsearch').val('');

        $(self.$data.mytable).block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        self.$data.mytable.bootstrapTable('load',[]);


        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/purchasepayment/finyear/${store.state.user.finyear}/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            if( !_.isNull(resp.data)){
              self.$data.mytable.bootstrapTable('load', resp.data);
            }
          } else {
            swal ( { title: "Oh noes!" ,  text: resp.message, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        }).finally(function () {
          $(self.$data.mytable).unblock();
          $(self.$data.mytable).bootstrapTable('resetView');
        });

      }
    }

  }
</script>

<style scoped>

  .modal-content {
    padding: 0px;
  }
</style>
